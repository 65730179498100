export const generalDeLegacy = {
  "catalogSelector.brands.plural.zero": "{number} Marken",
  "catalogSelector.brands.plural.one": "{number} Marke",
  "catalogSelector.brands.plural.two": "{number} Marken",
  "catalogSelector.brands.plural.few": "{number} Marken",
  "catalogSelector.brands.plural.many": "{number} Marken",
  "catalogSelector.brands.plural.other": "{number} Marken",
  "catalogSelector.category.plural.zero": "{number} Kategorien",
  "catalogSelector.category.plural.one": "Kategorie: {number}",
  "catalogSelector.category.plural.two": "{number} Kategorien",
  "catalogSelector.category.plural.few": "{number} Kategorien",
  "catalogSelector.category.plural.many": "{number} Kategorien",
  "catalogSelector.category.plural.other": "{number} Kategorien",
  "catalogSelector.product.plural.zero": "{number} Produkte",
  "catalogSelector.product.plural.one": "{number} Produkt",
  "catalogSelector.product.plural.two": "{number} Produkte",
  "catalogSelector.product.plural.few": "{number} Produkte",
  "catalogSelector.product.plural.many": "{number} Produkte",
  "catalogSelector.product.plural.other": "{number} Produkte",
  "catalogSelector.selected.single": "{items} ausgewählt",
  "catalogSelector.selected.multiple": "{items} und {lastItems} ausgewählt",
  "iconGallery.uploadResult.failMessage":
    "Beim Hochladen ist ein Fehler aufgetreten. Stellen Sie sicher, dass die Dateien den Anforderungen entsprechen\n{showFileList,select,true{{failed} von {total} fehlgeschlagen: {failedDetails}} andere {{failedDetails}}} ",
  "pointsRatesFields.perDescriptor.spend": "für jede/n/s",
  "activityElementMultiuplyPointsText.double": "Doppelt",
  "activityElementMultiuplyPointsText.triple": "Dreifach"
};
