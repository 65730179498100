export const actionsDeLegacy = {
  "actions.notification.purchase":
    "Um den Kaufvorgang zu aktivieren, {helpLink} die Aktion zu Ihren Kontakten {learnMoreLink}.",
  "actions.notification.engagement":
    "Um den Kaufvorgang zu aktivieren, {helpLink} die Aktion zu Ihren Kontakten {learnMoreLink}.",
  "actions.notification.join":
    "Die Standardbeitrittsaktion arbeitet automatisch, nachdem sie aktiviert und der Plan gestartet wurde.  Zusätzliche Beitrittsaktionen  müssen {helpLink}{learnMoreLink}.",
  "actions.addButton.text": "Neue {actionType}-Aktion anlegen",
  "actionSetup.title.new": "Neu: {actionType}",
  "actionSetup.title.edit": "{actionType} Setup - {actionName}",
  "actionSetup.referralContent.referrer.text":
    "Für jeden Freund, der Ihren Angebotscode verwendet, um das erste Mal zu bestellen, erhalten Sie {token}.",
  "actionSetup.alert.activeMustDeactivate.deactivateText": "deaktivieren",
  "actionSetup.alert.activeMustDeactivate":
    "Diese Aktion ist aktiv und ihre Einstellungen sind gesperrt. Um diese Aktion zu bearbeiten, müssen Sie zuerst Folgendes tun:  {deactivateTag}. Anschließend müssen Sie auf das Ablaufdatum warten.",
  "actionSetupContentCreation.editSide.title.tooltip.default":
    "Eine Standardnachricht ersetzt die ursprünglichen Nachrichten-Token, wenn System- oder Netzwerkfehler vorliegen. Die festgelegten Token {message} {cta text} {cta url}  werden im  Smart Block und der Loyalty Wallet angezeigt.",
  "actionSetupContentCreation.editSide.title.tooltip.join":
    "Die festgelegten Token {message} {cta text} {cta url}  werden im  Smart Block und der Loyalty Wallet angezeigt. Die Standardnachricht wird angezeigt, wenn keine definierte festgelegte Beitrittsnachricht vorliegt.",
  "actionSetupContentCreation.editSide.title.tooltip.other":
    "Die festgelegten Token {message}{cta text}{cta url} erscheinen im Smart Block und der Loyalty Wallet. Sie können diese Token einzeln oder mit dem Content-Block im Kampagneneditor verwenden.",
  "actionSetupContentCreation.previewSideTitleTooltip.tokens":
    "Beispiel wie {loyalty tokens} im E-Mail-Content-Block angezeigt wird. Das eigentliche Design kann je nach E-Mail-Design variieren.",
  "actionSetupGeneral.actionNameInput.other.label": "{actionTypeText}-Name",
  "actionSetupGeneral.actionInternalNameInput.other.label": "{actionTypeText} Interner Name",
  "actionSetupGeneral.actionNameInput.other.tooltip":
    "Geben Sie den Namen für {actionTypeText} ein, das den Mitgliedern angezeigt wird. Der Name erscheint ebenfalls als Loyalty-Token im Personalisierungsservice.",
  "actionSetupLoyaltyMessage.tooltip.members.nonDefault":
    "Diese Nachricht erscheint Mitgliedern für diese {actionTypeText}. Dieses Token umfasst das Belohnungs-Token.",
  "actionSetupLoyaltyMessagePreviewEmail.notification.tokens":
    "Beispiel wie {loyalty tokens} im E-Mail-Content-Block angezeigt wird. Das tatsächliche Design kann je nach E-Mail-Design variieren.",
  "actionSetupRewards.pointsType.popup.message":
    'Wenn Sie die Punktart ändern, wird die Option mehrere Belohnungen entfernt, denn mehrere Belohnungen für Stufen {tiers} ist mit der von Ihnen gewählten Punktart nicht kompatibel! Wenn Sie mehrere Punkte vergeben möchten, können Sie die Punktart ändern und erneut "Mehrere Punkte" auswählen.',
  "actionSetupRewards.accordionTierItem.tooltip.all.disabled":
    'Es ist nicht möglich die Belohnung "Mehrere Punkte" für diese Aktion mit {pointType} zu vergeben, da einige der Stufen ({tierNames}) {pointType} nicht standardmäßig unterstützen. Aktualisieren Sie diese Stufen, oder wählen Sie eine andere Belohnungsart aus.',
  "actionSetupRewards.accordionTierItem.tooltip.tier.disabled":
    'Es ist nicht möglich die Belohnung "Mehrere Punkte" zu vergeben, da diese Stufe {pointType}  einfache Käufe nicht unterstützt.',
  "actionSetupRewards.accordionTierItem.tooltip.pointType.status": "Statuspunkte",
  "actionSetupRewards.accordionTierItem.tooltip.pointType.balance": "Ausgabenpunkte",
  "actionSetupScheduling.recurringType.recurring.tzMismatchNotifiation":
    "Die Werbeaktion wurde gemäß der Zeitzone {timezone} eingerichtet. Daher erfolgen alle Änderungen am Start- und Enddatum unter Berücksichtigung dieser Zeitzone.",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.zero": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.one": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.two": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.few": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.many": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.other": "{day}e(r)",
  "actionSetupScheduling.recurring.summary.week.name.1": "Erste(r)",
  "actionSetupScheduling.recurring.summary.week.name.2": "Zweite(r)",
  "actionSetupScheduling.recurring.summary.week.name.3": "Dritte(r)",
  "actionSetupScheduling.recurring.summary.week.name.4": "Vierte(r)",
  "actionSetupScheduling.recurring.summary.week.name.5": "Letzte(r)",
  "actionValidator.constraints.isBlockMessage.isBlockMessage.emptyTokenMessage": "{fieldName} ist erforderlich.",
  "actionValidator.constraints.url": "{fieldName} muss eine gültige URL sein.",
  defaultJoinMessageWithReward:
    "Treten Sie unserem Treueprogrammplan jetzt {rewardToken} bei und erhalten Sie Punkte für künftige Käufe."
};
