export const actionsEnLegacy = {
  "actions.notification.purchase":
    "To activate the purchase action, {helpLink} the action to your contacts. {learnMoreLink}.",
  "actions.notification.engagement":
    "To activate the engagement action, {helpLink} the action to your contacts. {learnMoreLink}.",
  "actions.notification.join":
    "The Default join action will work automatically after it is activated and the plan is launched. Additional Join actions require to be {helpLink}. {learnMoreLink}.",
  "actions.addButton.text": "Create New {actionType} Action",
  "actionSetup.title.new": "New {actionType}",
  "actionSetup.title.edit": "{actionType} Setup - {actionName}",
  "actionSetup.referralContent.referrer.text":
    "For each friend that uses your offer code to order for the first time, you'll earn {token}.",
  "actionSetup.alert.activeMustDeactivate.deactivateText": "deactivate it",
  "actionSetup.alert.activeMustDeactivate":
    "This action is active and its settings are locked. To edit this action, you must first {deactivateTag} and wait for the expiration date.",
  "actionSetupContentCreation.editSide.title.tooltip.default":
    "A default message replaces the original message tokens if there are system or network errors. The defined {message} {cta text} {cta url} tokens display in the Smart Block and Loyalty Wallet.",
  "actionSetupContentCreation.editSide.title.tooltip.join":
    "The defined {message} {cta text} {cta url} tokens display in the Smart Block and Loyalty Wallet. Default message displays  when there is no defined join message.",
  "actionSetupContentCreation.editSide.title.tooltip.other":
    "The defined {message} {cta text} {cta url} tokens display in the Smart Block and Loyalty Wallet. You can use these tokens separately or with content block in the Campaign Editor.",
  "actionSetupContentCreation.previewSideTitleTooltip.tokens":
    "Example of how {loyalty tokens} displays in the email content block. The actual design can vary depending on your email design.",
  "actionSetupGeneral.actionNameInput.other.label": "{actionTypeText} Name",
  "actionSetupGeneral.actionInternalNameInput.other.label": "Internal {actionTypeText} Name",
  "actionSetupGeneral.actionNameInput.other.tooltip":
    "Enter a name for this {actionTypeText} to display to members. The name also displays as a Loyalty token in the Personalization Service.",
  "actionSetupLoyaltyMessage.tooltip.members.nonDefault":
    "This message displays to members for this {actionTypeText}. This token includes the reward token.",
  "actionSetupLoyaltyMessagePreviewEmail.notification.tokens":
    "Example of how {loyalty tokens} displays in the email content block. The actual design can vary depending on your email design.",
  "actionSetupRewards.pointsType.popup.message":
    'If you change the Point Type, the Multiple Point option will be removed because multiply reward for tiers {tiers} is not compatible with the points type you choose! If you want to give multiple points you can change the Point Type and select "Multiple Points" again.',
  "actionSetupRewards.accordionTierItem.tooltip.all.disabled":
    'It is not possible to give the "Multiple Points" reward for this action with {pointType} as some of the tiers ({tierNames}) do not support {pointType} by default. Update these tiers, or select another reward type.',
  "actionSetupRewards.accordionTierItem.tooltip.tier.disabled":
    'It is not possible to give the "Multiple Points" reward as this tier does not support {pointType} for basic purchases.',
  "actionSetupRewards.accordionTierItem.tooltip.pointType.status": "status points",
  "actionSetupRewards.accordionTierItem.tooltip.pointType.balance": "spend points",
  "actionSetupScheduling.recurringType.recurring.tzMismatchNotifiation":
    "The promotion was set up according to the {timezone} timezone. Therefore, any changes to the start and end times will be considered according to that timezone.",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.zero": "{day}th",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.one": "{day}st",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.two": "{day}nd",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.few": "{day}rd",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.many": "{day}th",
  "actionSetupScheduling.recurring.summary.followingDays.daySuffix.ordinal.other": "{day}th",
  "actionSetupScheduling.recurring.summary.week.name.1": "first",
  "actionSetupScheduling.recurring.summary.week.name.2": "second",
  "actionSetupScheduling.recurring.summary.week.name.3": "third",
  "actionSetupScheduling.recurring.summary.week.name.4": "fourth",
  "actionSetupScheduling.recurring.summary.week.name.5": "last",
  "actionValidator.constraints.isBlockMessage.isBlockMessage.emptyTokenMessage": "{fieldName} is required.",
  "actionValidator.constraints.url": "{fieldName} must be a valid url.",
  defaultJoinMessageWithReward:
    "Join our Loyalty plan now and get {{rewardToken}} and can earn points your future purchases."
};
