export const generalEnLegacy = {
  "catalogSelector.brands.plural.zero": "{number} brands",
  "catalogSelector.brands.plural.one": "{number} brand",
  "catalogSelector.brands.plural.two": "{number} brands",
  "catalogSelector.brands.plural.few": "{number} brands",
  "catalogSelector.brands.plural.many": "{number} brands",
  "catalogSelector.brands.plural.other": "{number} brands",
  "catalogSelector.category.plural.zero": "{number} categories",
  "catalogSelector.category.plural.one": "{number} category",
  "catalogSelector.category.plural.two": "{number} categories",
  "catalogSelector.category.plural.few": "{number} categories",
  "catalogSelector.category.plural.many": "{number} categories",
  "catalogSelector.category.plural.other": "{number} categories",
  "catalogSelector.product.plural.zero": "{number} products",
  "catalogSelector.product.plural.one": "{number} product",
  "catalogSelector.product.plural.two": "{number} products",
  "catalogSelector.product.plural.few": "{number} products",
  "catalogSelector.product.plural.many": "{number} products",
  "catalogSelector.product.plural.other": "{number} products",
  "catalogSelector.selected.single": "{items} selected",
  "catalogSelector.selected.multiple": "{items} and {lastItems} selected",
  "iconGallery.uploadResult.failMessage":
    "Something went wrong during the upload. Be sure the files meet the requirements. \n{showFileList,select,true{{failed} out of {total} failed: {failedDetails}} other{{failedDetails}}} ",
  "pointsRatesFields.perDescriptor.spend": "for every",
  "pointsRatesFields.perDescriptor.spend": "for every",
  "activityElementMultiuplyPointsText.double": "Double",
  "activityElementMultiuplyPointsText.triple": "Triple"
};
